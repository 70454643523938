import React from "react"

import "../../scss/style.scss"

import SEO from "../../components/seo"

import Top from "../../components/luca-pay/top"
import Main from "../../components/luca-pay/sign-main"
import Footer from "../../components/footer"

import seoImage from "../../images/luca-pay/bg1.png"

export default function ExpressionInterest(props) {
    return (
        <div className="h-full font-robo signup lucapay">
            <SEO title="LUCA Pay" einvoice={true} description="" image={seoImage}
                 keywords="e-invoicing partner program"/>
            <Top/>
            <div className="signup__top">
                <div className="overlay"/>
                <div className="container z-20">
                    <div className="title mx-auto">
                        Expressions of Interest
                    </div>
                </div>
            </div>

            <Main {...props} lucapay={true} />
            <Footer simple={true} lucapay={true}/>
        </div>
    )
}
