import React from "react"
import lucapayLogoImage from "../../images/luca-pay/lucapay-logo.svg"
import {Link} from "gatsby";
import Logo from "../../images/logo.svg";
import classNames from "classnames";

export default function Top({navItemClick, setToggle, toggle, toggleConfirmationModal}) {
  const toggleCls = classNames('navbar-toggler',
      { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
      { 'show': toggle }
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }
  return (
    <div className="top">
      <div className="container">
        <header className="top-header site-header site-header--nofix">
          <div className="">
            <div className="header-inner">
              <nav className="site-nav">
                <a href="/" className="logo flex items-center">
                  <img src={lucapayLogoImage} alt="logo" className="pr-3"/>
                  <div className="text-neutral-3 whitespace-no-wrap ml-3 sm:text-16 text-14">
                    Powered by LUCA Plus
                  </div>
                </a>

                <div id="site-menu-wrapper">
                  <ul className="site-menu">
                    <li onClick={() => navItemClick("FAQ")}>
                      <a href="https://go.luca.plus/luca-pay-nav-faq" className="hover:text-gray-26 mx-8" >
                      FAQ
                    </a>
                    </li>
                    <li onClick={() => navItemClick("Log In")}>
                      <a className="hover:text-gray-26 mx-8" href={process.env.GATSBY_APP_URL + "/login"}>
                        Log In
                      </a>
                    </li>
                    <li onClick={() => navItemClick("Sign Up")}>
                      <a className="text-white mx-8" onClick={toggleConfirmationModal}>
                        <button
                            className="signup-btn focus:outline-none"
                        >
                          Sign Up
                        </button>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                <span className="hamburger">
                  <span/>
                  <span/>
                  <span/>
                </span>
                <span className="hamburger-cross">
                  <span/>
                  <span/>
                </span>
              </span>
                </div>
              </nav>
            </div>
          </div>
          <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
            <ul className="mobile-menu">
              <li onClick={() => navItemClick("FAQ")}><a href="https://go.luca.plus/luca-pay-nav-faq" className="hover:text-gray-26 mx-10" >FAQ</a></li>
              <li onClick={() => navItemClick("Log In")}><a className="hover:text-gray-26 mx-10" href={process.env.GATSBY_APP_URL + "/login"}>Log In</a></li>
              <li onClick={() => navItemClick("Sign Up")}><a className="text-white mt-6" href={process.env.GATSBY_APP_URL + "/login"}>
                  <button className="signup-btn focus:outline-none">
                    Sign Up
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </div>
  )
}
