import React from "react"
import SignupForm from "./signupForm"

export default function Main(props) {
  return (
     <div className="main">
      <p className="desc">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras malesuada fermentum faucibus elementum. Egestas proin lectus euismod dui. Amet viverra augue lectus mauris sagittis interdum.
        <br />
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras malesuada fermentum faucibus elementum. Egestas proin lectus euismod dui. Amet viverra augue lectus mauris sagittis interdum.
      </p>
     <SignupForm  />
    </div>
  )
}
